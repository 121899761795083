.listingPoint {
    ul {
        margin: 0 0 24px 20px;
        padding: 0;
        li {
            color: #0C2074;
        }
    }
}
.headerDiv{
    background-color: #F7F7FA;
  }
@media (max-width: 766px) {
    iframe{
        display: initial !important;
    }
    .serviceDiv{
        display: flex !important;
        flex-direction: column !important;
    }
    .headerDiv{
        background-color: white !important;
    }
    
  }
  @media (min-width : 767px) and (max-width: 1024px) {
    iframe{
        display: initial !important;
    }
    .serviceDiv{
        display: flex !important;
    }
    .serivceRight{
        margin-top: 65px !important;
    }
  }
